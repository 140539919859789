import React, { useState } from 'react'
export default function Gallery({ tallImages, wideImages, more }) {

  const [moreImage, setMoreImage] = useState(false);
  const fullShortArray = tallImages.length > wideImages.length ? wideImages : tallImages
  const fullLongArray  = tallImages.length > wideImages.length ? tallImages : wideImages

  const shorterArray   = moreImage ? fullShortArray.slice(0,Math.floor(fullShortArray.length/3)*3) : fullShortArray.slice(0,3)
  const longerArray    = moreImage ? fullLongArray : fullLongArray.slice(0,3)
  const imageBox = (first, last, index)=> {
    return (
      <div key={`${first} ${last} ${index}`} className=" px-4">
        <div>
          <a href={first} data-lightbox="pod-images">
            <img src={first} className="mb-6 rounded-xl" />
          </a>
        </div>
        <div>
          <a href={last} data-lightbox="pod-images">
            <img src={last} className="mb-6 rounded-xl" />
          </a>
        </div>
     </div>
    )
  }

  return (
    <section className="overflow-hidden bg-tuna-ellipsis sm:pt-36 py-12">
      <div className="container relative z-20">
        <h2 className="title mb-12 text-center">Inside the pod</h2>
        <h2 className="title title--bg whitespace-nowrap text-tuna-o-4">Inside the pod</h2>
        <div className="grid grid-cols-3 justify-center -mx-4">
            {shorterArray.map( (url, index) => (
              index%2 == 0 ? imageBox(shorterArray[index],longerArray[index], index) : imageBox(longerArray[index],shorterArray[index], index)
            ))}
        </div>

        <div className="relative sm:-mt-12 text-center z-20">
          <button className="btn btn--primary sm:w-auto w-full" onClick={() => setMoreImage(!moreImage)}>
            { moreImage ? "Show less" : "Load more" }
          </button>
        </div>
      </div>
    </section>
  )
}