import React from 'react'
import PodConstruction from './PodConstruction'
import PodsAssembly from './PodsAssembly'
import MechanicalEletricalServices from './MechanicalEletricalServices'
import PreparationForDispatch from './PreparationForDispatch'
import DeliveryInstallation from './DeliveryInstallation'
import Warranties from './Warranties'
import AdditionalInformation from './AdditionalInformation'
import CapacityOutput from './CapacityOutput'
import PeopleExperience from './PeopleExperience'
import BtnTab from '../BtnTab'

export default function TechnicalData({
  activeTechnicalTab,
  setActiveTechnicalTab,
  technicalDataTabs,
}) {
  return (
    <div>
      <h2 className="page-title mb-6">Technical data</h2>

      <p className="mb-12 sm:text-xl">
        UNIPODS designs, manufactures, delivers and installs Off-Site Modular Construction Pods. We offer a full turn-key solution, designed and tailor made to meet our client’s specific project needs.
        <br />
        <br />
        Pods are complete modular units consisting of floor, walls, and ceiling. Each pod is delivered to the site with all internal finishes 100% complete. This includes all floor & wall tiling, sanitary ware, fittings and fixtures, plumbing and electrical systems.
      </p>

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeTechnicalTab}
          name={technicalDataTabs[0].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveTechnicalTab(activeTechnicalTab === technicalDataTabs[0].name ? '' : technicalDataTabs[0].name)
          }}
          text={technicalDataTabs[0].text}
        />
      </div>

      {activeTechnicalTab === 'PodConstruction' && <PodConstruction />}

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeTechnicalTab}
          name={technicalDataTabs[1].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveTechnicalTab(activeTechnicalTab === technicalDataTabs[1].name ? '' : technicalDataTabs[1].name)
          }}
          text={technicalDataTabs[1].text}
        />
      </div>

      {activeTechnicalTab === 'PodsAssembly' && <PodsAssembly />}

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeTechnicalTab}
          name={technicalDataTabs[2].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveTechnicalTab(activeTechnicalTab === technicalDataTabs[2].name ? '' : technicalDataTabs[2].name)
          }}
          text={technicalDataTabs[2].text}
        />
      </div>

      {activeTechnicalTab === 'MechanicalEletricalServices' && <MechanicalEletricalServices />}

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeTechnicalTab}
          name={technicalDataTabs[3].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveTechnicalTab(activeTechnicalTab === technicalDataTabs[3].name ? '' : technicalDataTabs[3].name)
          }}
          text={technicalDataTabs[3].text}
        />
      </div>

      {activeTechnicalTab === 'PreparationForDispatch' && <PreparationForDispatch />}

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeTechnicalTab}
          name={technicalDataTabs[4].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveTechnicalTab(activeTechnicalTab === technicalDataTabs[4].name ? '' : technicalDataTabs[4].name)
          }}
          text={technicalDataTabs[4].text}
        />
      </div>

      {activeTechnicalTab === 'DeliveryInstallation' && <DeliveryInstallation />}

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeTechnicalTab}
          name={technicalDataTabs[5].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveTechnicalTab(activeTechnicalTab === technicalDataTabs[5].name ? '' : technicalDataTabs[5].name)
          }}
          text={technicalDataTabs[5].text}
        />
      </div>

      {activeTechnicalTab === 'Warranties' && <Warranties />}

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeTechnicalTab}
          name={technicalDataTabs[6].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveTechnicalTab(activeTechnicalTab === technicalDataTabs[6].name ? '' : technicalDataTabs[6].name)
          }}
          text={technicalDataTabs[6].text}
        />
      </div>

      {activeTechnicalTab === 'AdditionalInformation' && <AdditionalInformation />}

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeTechnicalTab}
          name={technicalDataTabs[7].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveTechnicalTab(activeTechnicalTab === technicalDataTabs[7].name ? '' : technicalDataTabs[7].name)
          }}
          text={technicalDataTabs[7].text}
        />
      </div>

      {activeTechnicalTab === 'CapacityOutput' && <CapacityOutput />}

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeTechnicalTab}
          name={technicalDataTabs[8].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveTechnicalTab(activeTechnicalTab === technicalDataTabs[8].name ? '' : technicalDataTabs[8].name)
          }}
          text={technicalDataTabs[8].text}
        />
      </div>

      {activeTechnicalTab === 'PeopleExperience' && <PeopleExperience />}
    </div>
  )
}
