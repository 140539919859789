import React from 'react'

export default function Kitchen() {

  return (
    <React.Fragment>
      <p>
        Building a kitchen pod off-site is the most innovative way to design and build a kitchen for any project. The building process ensures a superior and consistent quality and an on-time and on-budget delivery every time. Click <a className="underline" href="/modular_construction">here</a> and learn all about how the pod is made and see why Unipods is the leader in the pod technology industry
      </p>
    </React.Fragment>
  )
}