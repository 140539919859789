import React from 'react'

import imageCasting from 'images/unipod/casting.jpg'

export default function Casting() {
  return (
    <div>
      <p className="font-bold mb-10 text-2xl">Casting</p>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Casting" src={imageCasting} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p>
            The floor base of the pod is cast with a fiber reinforced concrete mixture. The base is reinforced with steel mesh and rebar in accordance with the pod drawings and building codes.
            <br />
            <br />
            The walls are made from cold rolled steel profiles and moisture resistant fiberboard walls (steel/ gypsum board pod).
            <br />
            <br />
            Plumbing pipe work and wastes along with the electrical services are cast in the floor and wall. Inserts for lifting rods are cast into the floor and concrete walls for delivery and placement of the pod.
          </p>
        </div>
      </div>
    </div>
  )
}
