import React from 'react'
import ImageCapacityOutput1 from 'images/unipod/capacity_output.jpg'

export default function CapacityOutput() {
  return (
    <div>
      <p className="mb-12 sm:text-xl">
        <b>Capacity / Output</b>
        <br />
        On a single standard shift, we have capacity to manufacture over 10,000 pods per annum. This equates to approximately 200 completed pods per week.
      </p>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Capacity / Output" src={ImageCapacityOutput1} />
        </div>
      </div>
    </div>
  )
}
