import React, { lazy, Suspense, useState } from 'react'
import BathroomPodImage from 'images/unipod/bathroom-pod-card-picture.jpg'
import KitchenPodImage from 'images/unipod/kitchen-pod-card-picture.jpg'
import MultiUsePodImage from 'images/unipod/multi-use-pod-card-picture.jpg'
const Bathroom = lazy(() => import('./types/Bathroom'))
const Kitchen = lazy(() => import('./types/Kitchen'))
const MultiUse = lazy(() => import('./types/MultiUse'))

export default function PodsType() {
  const [activeTab, setActiveTab] = useState('BathroomPods')
  return (
    <main className="page-padding">
      <section className="">
        <div className="container container--main py-12">
          <div className="mb-12">
            <div className="flex justify-center">
              <h1 className="mb-16 text-4xl">POD TYPES</h1>
            </div>
            <div className="flex justify-center flex-col lg:flex-row -mx-4">
              <div className="mb-8 px-4 lg:w-1/3">
                <div className={`box-white p-6 relative flex items-center flex-col cursor-pointer ${activeTab === 'BathroomPods' && 'border-red-600 lg:border-white border-2 lg:border-0 border-solid' }`} onClick={() => setActiveTab('BathroomPods')}>
                  <img className="rounded-xl w-full h-56 hidden lg:block object-cover lg:m-0 mx-auto" src={BathroomPodImage} alt="bathroom-pod-card-picture.jpg"/>
                  <p className="text-2xl mt-6 text-center">Bathroom pods</p>
                  { activeTab === 'BathroomPods' && <div className="absolute bg-red-600 bottom-0 w-full h-4 rounded-b-md hidden lg:block" /> }
                </div>
              </div>
              <div className="mb-8 px-4 lg:w-1/3">
                <div className={`box-white p-6 relative flex items-center flex-col cursor-pointer ${activeTab === 'KitchenPods' && 'border-red-600 lg:border-white border-2 lg:border-0 border-solid' }`} onClick={() => setActiveTab('KitchenPods')}>
                  <img className="rounded-xl w-full h-56 hidden lg:block object-cover lg:m-0 mx-auto" src={KitchenPodImage} alt="bathroom-pod-card-picture.jpg"/>
                  <p className="text-2xl mt-6 text-center">Kitchen pods</p>
                  { activeTab === 'KitchenPods' && <div className="absolute bg-red-600 bottom-0 w-full h-4 rounded-b-md hidden lg:block" /> }
                </div>
              </div>
              <div className="mb-8 px-4 lg:w-1/3">
                <div className={`box-white p-6 relative flex items-center flex-col cursor-pointer ${activeTab === 'MultiUsePods' && 'border-red-600 lg:border-white border-2 lg:border-0 border-solid' }`} onClick={() => setActiveTab('MultiUsePods')}>
                  <img className="rounded-xl w-full h-56 hidden lg:block object-cover lg:m-0 mx-auto" src={MultiUsePodImage} alt="bathroom-pod-card-picture.jpg"/>
                  <p className="text-2xl mt-6 text-center">Multi-use pods</p>
                  { activeTab === 'MultiUsePods' && <div className="absolute bg-red-600 bottom-0 w-full h-4 rounded-b-md hidden lg:block" /> }
                </div>
              </div>
            </div>
            <Suspense fallback={<div></div>}>
              { activeTab === 'BathroomPods' && <Bathroom /> }
              { activeTab === 'KitchenPods' && <Kitchen /> }
              { activeTab === 'MultiUsePods' && <MultiUse /> }
            </Suspense>

          </div>
        </div>
      </section>
    </main>
  )
}