import React from 'react'

export default function Bathroom() {

  return (
    <React.Fragment>
      <p>
        Entirely built off-site, the bathroom pods are the most efficient solution to design, build and install toilets on any construction site.
        The design possibilities are endless and the off-site modular construction enables the contractor to be in full control of the process, manage cost and time efficiently. Click <a className="underline" href="/modular_construction">here</a> and learn all about how the pod is made and see why Unipods is the leader in the pod technology industry.
      </p>
    </React.Fragment>
  )
}