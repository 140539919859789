import React from 'react'

import imageFirstPhase from 'images/unipod/first_phase_plumbing.jpg'

export default function Plumbing1() {
  return (
    <div>
      <p className="font-bold mb-10 text-2xl">First Phase of Plumbing</p>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="First Phase of Plumbing" src={imageFirstPhase} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p>
            The 1st phase of plumbing is completed prior to any fittings or features being installed. This process ensures all pipe work, wastes, fittings, etc receive exact cutouts during the tiling process.
          </p>
        </div>
      </div>
    </div>
  )
}
