import React from 'react'

import imageCompletion from 'images/unipod/completion.jpg'

export default function Completion() {
  return (
    <div>
      <p className="font-bold mb-10 text-2xl">Completion</p>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Completion" src={imageCompletion} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p>
            The pods are cleaned prior to the final inspection by our Quality Control team. After sign-off, an OSB (or similar) board is fitted over the pod entrance and it is securely locked. The Pods are then wrapped for protection during transportation.
          </p>
        </div>
      </div>
    </div>
  )
}
