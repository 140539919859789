require('@rails/ujs').start()
//require('turbolinks').start()
require('@rails/activestorage').start()
require('jquery')
require('./vendors/slick.min')
require('./slick')
require('./vendors/lightbox')
require('./vendors/select2.min')
require('./select2')

var componentRequireContext = require.context('components/unipod', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);