import React from 'react'

export default function AdditionalInformation() {
  return (
    <div>
      <p className="mb-12 sm:text-xl">
        <b>Additional Information</b>
        <br />
        Facts & Figures
        <br />
        <br />
        UNIPODS purpose-built modular pod manufacturing facility is located in Al Ghail within the emirate of Ras Al Khaimah, United Arab Emirates.
        <br />
        <br />
        Our facility includes:
      </p>

      <ul className="list-disc mb-12 pl-6 sm:text-xl">
        <li>A main production hall & store, 13,000m2, housing two 2 5t overhead cranes</li>
        <li>A casting hall including batching plant, 4200m2, with two.5t overhead cranes</li>
        <li>A designated on-site storage area for completed pods totalling 7000m2</li>
        <li>A kitchen factory, 4000m2, housing the most technologically advanced kitchen manufacturing capability in the middle east / gulf region</li>
        <li>An overall site size of 100,000m2 leaving space for future expansion plans</li>
      </ul>
    </div>
  )
}
