import React, { lazy, Suspense } from 'react'
import NotificationProvider from '../../../contexts/NotificationContext'
const CareerForm = lazy(() => import(/* webpackChunkName: "CareerForm" */'../../shared/career/CareerForm'))

export default function CareerFormWrapper() {

  return (
    <Suspense fallback={<div></div>}>
      <NotificationProvider>
        <CareerForm siteName="Unipod" />
      </NotificationProvider>
    </Suspense>
  )
}