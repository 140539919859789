import React from 'react'

import ImagePeopleExperience1 from 'images/unipod/people_experience_1.jpg'

export default function PeopleExperience() {
  return (
    <div>
      <p className="mb-12 sm:text-xl">
        <b>People & Experience</b>
        <br />
        The UNIPODS management team is highly experienced in the off-site construction industry with a strong track record in managing leading bathroom pod manufacturing companies in the UK and Scandinavia. Design, production, technical and management team members have been handpicked from leading European Off-site Construction Technology companies and bring with them a wealth of experience and knowledge. The team is complemented by a number of key people who have extensive and in-depth knowledge and experience of the UAE and Gulf Region Construction Market.
      </p>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="People & Experience" src={ImagePeopleExperience1} />
        </div>
      </div>
    </div>
  )
}
