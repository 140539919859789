import React from 'react'

export default function Warranties() {
  return (
    <div>
      <p className="mb-12 sm:text-xl">
        <b>Warranties, Maintenance, and Defects</b>
        <br />
        UNIPODS provides a 10 year warranty on the pod structure. We also pass on all manufacturers’ warranties for the products and materials used within the construction and fit-out of the pod.
        <br />
        <br />
        On a project by project basis, we will commit to a maintenance and defects liability period (typically 12 months from practical completion).
        <br />
        <br />
        In addition to the above, UNIPODS LLC carries general liability insurance.
      </p>
    </div>
  )
}
