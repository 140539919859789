import React, { lazy, Suspense } from 'react'
const Slider = lazy(() => import(/* webpackChunkName: "Slider" */'react-slick'))

import iconCost from 'images/unipod/icon-cost.svg'
import iconSpeed from 'images/unipod/icon-speed.svg'
import iconQuality from 'images/unipod/icon-quality.svg'
import iconConvenience from 'images/unipod/icon-convenience.svg'

export default function Benefits() {
  const settings = {
    autoplay: false,
    dots: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 4400,
        settings: "unslick"
      },
    ]
  }

  return (
    <div>
      <h2 className="page-title mb-6">Benefits</h2>

      <p className="mb-12 sm:text-xl">
        <b>Guaranteed benefits</b>
        <br />
        Traditional assembly and installation can be a cumbersome experience involving the coordination of several different suppliers and on-site fitters. Also, our multi-cultural society can create communication barriers between the foremen and workers. The UNIPODS off-site construction methods streamline communication to only one point of contact. We design, build, and install as part of a full turn-key package. This can result in significant time savings and ensures the process is properly managed. Our thorough methods eradicate any potential hassles on site.
      </p>

      <div className="desktop-flex-wrapper mb-12 sm:mb-0">
        <Suspense fallback={<div></div>}>
          <Slider {...settings}>
            <div className="flex px-8 sm:mb-16 sm:w-1/2">
              <div className="box-white p-6 w-full">
                <img
                  alt="Quality"
                  className="mb-8 mx-auto w-full"
                  src={iconQuality}
                  style={{ maxWidth: 170 }}
                />

                <p className="font-bold mb-2 sm:text-3xl text-center text-guardsman-red">Quality</p>

                <p className="font-bold mb-4 sm:text-xl text-center">Benefit from Factory-controlled quality unmatched in traditional on-site construction.</p>

                <ul className="font-book list-disc pl-5 sm:text-base text-xs">
                  <li className="mb-2">Factory-controlled production process</li>
                  <li className="mb-2">Procedures monitored by our Quality Assurance Team</li>
                  <li className="mb-2">Live testing before delivery</li>
                  <li className="mb-2">Reference unit delivered for approval before construction begins</li>
                  <li className="mb-2">Thorough procedures prevent stalls during the crucial completion period</li>
                </ul>
              </div>
            </div>

            <div className="flex px-8 sm:mb-16 sm:w-1/2">
              <div className="box-white p-6 w-full">
                <img
                  alt="Speed"
                  className="mb-8 mx-auto w-full"
                  src={iconSpeed}
                  style={{ maxWidth: 170 }}
                />

                <p className="font-bold mb-2 sm:text-3xl text-center text-guardsman-red">Speed</p>

                <p className="font-bold mb-4 sm:text-xl text-center">Save up to 3 Months on your Construction Schedule</p>

                <ul className="font-book list-disc pl-5 sm:text-base text-xs">
                  <li className="mb-2">JIT deliveries optimise crane use and site time</li>
                  <li className="mb-2">Pods shorten on-site construction time up to 2-3 months</li>
                  <li className="mb-2">Quicker ROI for investor/customer</li>
                  <li className="mb-2">One point of contact from design to installation</li>
                </ul>
              </div>
            </div>

            <div className="flex px-8 sm:mb-16 sm:w-1/2">
              <div className="box-white p-6 w-full">
                <img
                  alt="Cost"
                  className="mb-8 mx-auto w-full"
                  src={iconCost}
                  style={{ maxWidth: 170 }}
                />

                <p className="font-bold mb-2 sm:text-3xl text-center text-guardsman-red">Cost</p>

                <p className="font-bold mb-4 sm:text-xl text-center">Reduce labor cost by as much as 40%</p>

                <ul className="font-book list-disc pl-5 sm:text-base text-xs">
                  <li className="mb-2">Experienced team adds value through optimization of the layout and specification</li>
                  <li className="mb-2">Strong supply chain ensures competitive material prices and a high level of service</li>
                  <li className="mb-2">An average of 15% less labor</li>
                  <li className="mb-2">Less recruitment, accommodation, and transportation</li>
                  <li className="mb-2">Less on-site supervision</li>
                  <li className="mb-2">Less planning and on-site coordination between traders and contractor</li>
                </ul>
              </div>
            </div>

            <div className="flex px-8 sm:mb-16 sm:w-1/2">
              <div className="box-white p-6 w-full">
                <img
                  alt="Convenience"
                  className="mb-8 mx-auto w-full"
                  src={iconConvenience}
                  style={{ maxWidth: 170 }}
                />

                <p className="font-bold mb-2 sm:text-3xl text-center text-guardsman-red">Convenience</p>

                <p className="font-bold mb-4 sm:text-xl text-center">Eliminate cost overruns, wastage, and abortive works</p>

                <ul className="font-book list-disc pl-5 sm:text-base text-xs">
                  <li className="mb-2">
                    Traditional assembly and installation of bathrooms and kitchens can be a complex and challenging experience with the requirement for coordination of a number of different suppliers and on site fitters. In addition, in a multi cultural society
                  </li>
                  <li className="mb-2">
                    With UNIPODS off site construction methods there is only one point of contact. We design build and install as part of the full turn-key package. This can result in significant time savings and ensures the process is controlled and managed…
                  </li>
                </ul>
              </div>
            </div>
          </Slider>
        </Suspense>
      </div>
    </div>
  )
}
