import React from 'react'

import ImageInstallationHorizontal1 from 'images/unipod/installation_horizontal_1.jpg'
import ImageInstallationHorizontal2 from 'images/unipod/installation_horizontal_2.jpg'
import ImageInstallationVertical1 from 'images/unipod/installation_vertical_1.jpg'
import ImageInstallationVertical2 from 'images/unipod/installation_vertical_2.jpg'

export default function Installation() {
  return (
    <div>
      <h2 className="page-title mb-6">Installation</h2>

      <p className="mb-12 sm:text-xl">
        <b>Horizontal Installation</b>
      </p>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Cradle Slide-In" src={ImageInstallationHorizontal1} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Cradle Slide-In</p>

          <p className="font-book">
            PODS are placed onto load moving skates within a specially prepared cradle. The cradle is then lifted with the use of a crane (supplied by the main contractor) to the designated access point and secured to the building.
          </p>
        </div>
      </div>

      <div className="flex flex-row-reverse flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Loading Platform Slide-In" src={ImageInstallationHorizontal2} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Loading Platform Slide-In</p>

          <p className="font-book">
            PODS are craned onto loading platforms situated at each floor access point (supplied by the main contractor).
          </p>
        </div>
      </div>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Forklift Slide-In" src={ImageInstallationVertical2} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Forklift Slide-In</p>

          <p className="font-book">
            PODS are lifted using a forklift directly from the delivery lorry onto moving skates positioned at the specified access point (Forklift supplied by the main contractor).
          </p>
        </div>
      </div>

      <p className="font-book mb-12 sm:text-2xl">
        <b>Vertical Installation</b>
      </p>

      <div className="flex flex-row-reverse flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Drop-In" src={ImageInstallationVertical1} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Drop-In</p>

          <p className="font-book">
            This method is implemented when building construction involves precast elements. Open access to each deck must be available. PODS will be craned from delivery lorries directly to a final designated area.
          </p>
        </div>
      </div>
    </div>
  )
}
