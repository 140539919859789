import React from 'react'

export default function MultiUse() {

  return (
    <React.Fragment>
      <p>
        The multi-use pod is the most versatile of all pods and suited for every need. Click <a className="underline" href="/modular_construction">here</a> and choose from a ready-made selection such as a desert pod, an office pod, a guest room pod and many more.
      </p>
    </React.Fragment>
  )
}