import React from 'react'
import Casting from './Casting'
import FloorTiling from './FloorTiling'
import CabinAssembly from './CabinAssembly'
import Tanking from './Tanking'
import Plumbing1 from './Plumbing1'
import WallTiling from './WallTiling'
import Plumbing2 from './Plumbing2'
import LiveTesting from './LiveTesting'
import Completion from './Completion'
import Delivery from './Delivery'
import BtnTab from '../BtnTab'

import iconTankingBlack from 'images/unipod/icon-tanking-black.svg'
import iconTankingWhite from 'images/unipod/icon-tanking-white.svg'
import iconPlumbing1Black from 'images/unipod/icon-plumbing-1-black.svg'
import iconPlumbing1White from 'images/unipod/icon-plumbing-1-white.svg'
import iconWallTilingBlack from 'images/unipod/icon-wall-tiling-black.svg'
import iconWallTilingWhite from 'images/unipod/icon-wall-tiling-white.svg'
import iconPlumbing2Black from 'images/unipod/icon-plumbing-2-black.svg'
import iconPlumbing2White from 'images/unipod/icon-plumbing-2-white.svg'
import iconLiveTestingBlack from 'images/unipod/icon-live-testing-black.svg'
import iconLiveTestingWhite from 'images/unipod/icon-live-testing-white.svg'
import iconCompletionBlack from 'images/unipod/icon-completion-black.svg'
import iconCompletionWhite from 'images/unipod/icon-completion-white.svg'
import iconDeliveryBlack from 'images/unipod/icon-delivery-black.svg'
import iconDeliveryWhite from 'images/unipod/icon-delivery-white.svg'
import iconCastingBlack from 'images/unipod/icon-casting-black.svg'
import iconCastingWhite from 'images/unipod/icon-casting-white.svg'
import iconCabinAssemblyBlack from 'images/unipod/icon-cabin-assembly-black.svg'
import iconCabinAssemblyWhite from 'images/unipod/icon-cabin-assembly-white.svg'
import iconFloorTilingBlack from 'images/unipod/icon-floor-tiling-black.svg'
import iconFloorTilingWhite from 'images/unipod/icon-floor-tiling-white.svg'

export default function ProductionFlow({
  activeProductionTab,
  productionTabs,
  setActiveProductionTab,
}) {
  return (
    <div>
      <h2 className="page-title mb-6">Production flow</h2>

      <p className="mb-12 sm:text-xl">
        Each modular pod is manufactured in the Ras Al Khaimah factory, where every workstation is designed to optimize quality, efficiency, and workmanship.
        <br />
        <br />
        All tools, anchors, and adhesives are sourced from top quality suppliers and applied under the right conditions to optimize performance.
      </p>

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeProductionTab}
          name={productionTabs[0].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveProductionTab(activeProductionTab === productionTabs[0].name ? '' : productionTabs[0].name)
          }}
          text={productionTabs[0].text}
        />
      </div>

      {activeProductionTab === 'Casting' && <Casting />}

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeProductionTab}
          name={productionTabs[1].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveProductionTab(activeProductionTab === productionTabs[1].name ? '' : productionTabs[1].name)
          }}
          text={productionTabs[1].text}
        />
      </div>

      {activeProductionTab === 'FloorTiling' && <FloorTiling />}

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeProductionTab}
          name={productionTabs[2].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveProductionTab(activeProductionTab === productionTabs[2].name ? '' : productionTabs[2].name)
          }}
          text={productionTabs[2].text}
        />
      </div>

      {activeProductionTab === 'CabinAssembly' && <CabinAssembly />}

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeProductionTab}
          name={productionTabs[3].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveProductionTab(activeProductionTab === productionTabs[3].name ? '' : productionTabs[3].name)
          }}
          text={productionTabs[3].text}
        />
      </div>

      {activeProductionTab === 'Tanking' && <Tanking />}

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeProductionTab}
          name={productionTabs[4].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveProductionTab(activeProductionTab === productionTabs[4].name ? '' : productionTabs[4].name)
          }}
          text={productionTabs[4].text}
        />
      </div>

      {activeProductionTab === 'Plumbing1' && <Plumbing1 />}

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeProductionTab}
          name={productionTabs[5].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveProductionTab(activeProductionTab === productionTabs[5].name ? '' : productionTabs[5].name)
          }}
          text={productionTabs[5].text}
        />
      </div>

      {activeProductionTab === 'WallTiling' && <WallTiling />}

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeProductionTab}
          name={productionTabs[6].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveProductionTab(activeProductionTab === productionTabs[6].name ? '' : productionTabs[6].name)
          }}
          text={productionTabs[6].text}
        />
      </div>

      {activeProductionTab === 'Plumbing2' && <Plumbing2 />}

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeProductionTab}
          name={productionTabs[7].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveProductionTab(activeProductionTab === productionTabs[7].name ? '' : productionTabs[7].name)
          }}
          text={productionTabs[7].text}
        />
      </div>

      {activeProductionTab === 'LiveTesting' && <LiveTesting />}

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeProductionTab}
          name={productionTabs[8].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveProductionTab(activeProductionTab === productionTabs[8].name ? '' : productionTabs[8].name)
          }}
          text={productionTabs[8].text}
        />
      </div>

      {activeProductionTab === 'Completion' && <Completion />}

      <div className="mb-6 sm:hidden text-center">
        <BtnTab
          activeTab={activeProductionTab}
          name={productionTabs[9].name}
          onClick={(e) => {
            e.preventDefault()
            setActiveProductionTab(activeProductionTab === productionTabs[9].name ? '' : productionTabs[9].name)
          }}
          text={productionTabs[9].text}
        />
      </div>

      {activeProductionTab === 'Delivery' && <Delivery />}
    </div>
  )
}
