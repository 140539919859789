import React from 'react'

import imageDelivery from 'images/unipod/delivery.jpg'

export default function Delivery() {
  return (
    <div>
      <p className="font-bold mb-10 text-2xl">Delivery</p>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Delivery" src={imageDelivery} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p>
            The pods are delivered on our own flatbed lorry to facilitate crane handling and installation. Each lorry can carry between 4-6 pods depending on the size of the pod.
            <br />
            We then commence inspection and installation.
          </p>
        </div>
      </div>
    </div>
  )
}
