import React from 'react'

import imagePodsCeilings from 'images/unipod/pods-ceilings.png'
import ImageDeliveryInstallation2 from 'images/unipod/delivery_installation_2.jpg'
import ImageDeliveryInstallation3 from 'images/unipod/delivery_installation_3.jpg'
import ImageDeliveryInstallation4 from 'images/unipod/delivery_installation_4.jpg'
import ImageDeliveryInstallation5 from 'images/unipod/delivery_installation_5.jpg'

export default function DeliveryInstallation() {
  return (
    <div>
      <p className="mb-12 sm:text-xl">
        <b>Delivery & Installation</b>
        <br />
        Pods are delivered on flat bed articulated lorries. The number of pods per load is dependent on the overall size and mix of pods required. The delivery programme is agreed upon and tailored to the site’s requirements. We provide timely delivery with the pods loaded in the sequence of intended installation.
        <br />
        There are a wide range of options available in terms of how the pods are physically lifted, maneuvered, and located within the building structure. The method selected for any given project will be dependent on many factors with consideration given to the type of structure, number of stories, method and sequence of construction, etc. Generally, there are two main types of installation: Horizontal or Vertical.
      </p>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Horizontal Installation" src={ImageDeliveryInstallation2} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Horizontal Installation</p>

          <p className="font-book">
            During horizontal installation, the pod is craned from the delivery vehicle and is normally placed on a platform at the intended installation level. From here, the pod is wheeled horizontally through an opening in the side of the structure and into the building. This is by far the most common method and the one typically used in multi-storey construction projects. It allows the main concrete structure to proceed in the traditional manner with pods being installed slightly later in the process after the floor above has been removed. The platform will typically be a cantilever type or can also be formed by a scaffold when overall storey heights allow.
            <br />
            <br />
            In some cases it may also be possible to use a specially designed lifting cage or cradle to lift the pod to the desired level. The cage is then strapped to the building allowing the pod to be wheeled out and into the building.
            <br />
            <br />
            On low-rise projects and lower floors of multi-storey buildings, it may also be possible for pods to be lifted to an opening on the intended installation floor by use of a fork-lift or telescopic handler.
          </p>
        </div>
      </div>

      <div className="flex flex-row-reverse flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Vertical installation" src={ImageDeliveryInstallation3} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Vertical installation</p>

          <p className="font-book">
            During vertical installation, pods are craned in from the delivery vehicle directly into the final location on the intended installation floor. Traditional insitu cast structures will not allow this type of installation as the pod would disrupt the formwork required for casting the slab/floor above. However, this does provide a very quick and practical option for low-rise structures using pre-cast floor elements or complete pre-cast concrete structures. The pods are delivered and installed on a just-in-time floor by floor basis in conjunction with erection of the pre-cast concrete element erection.
            <br />
            <br />
            In all cases, once installed the pods are typically installed on a minimum of four no-leveling points where loads will be transferred to the building structure. The overall weight of a pod is fully dependent upon the project’s specific design and the selection of finishes. However, generally the weight can be estimated at 450 Kg/m2 for a normal lightweight pod (concrete floor and lightweight walls).
            <br />
            <br />
            It is important to note that the introduction of the pod will in most cases not generate any additional weight on the structural slabs beyond what they are originally designed for. This is due to the fact that pod components generally act as substitution for items that have already been allowed for in the building design loads.
            <br />
            <br />
            Loading will be checked during the initial stages of pod implementation to ensure that the building structure into which the pod will be installed or rest upon has been suitably designed to accept the  pod loading as well as any live loading during installation.
          </p>
        </div>
      </div>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Lifting" src={ImageDeliveryInstallation4} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Lifting</p>

          <p className="font-book">
            The pod is generally supplied with four lifting points. The lifting points are manufactured using full height zinc plated steel threaded rods. The rods are mechanically secured to inserts cast within the concrete pod floor and terminate at high level with threaded sleeve connections to receive wire rope lifting eyelets. Threaded sleeves and wire rope lifting eyelets are certified to suit pod loading and are certified and supplied by UNIPODS. Pods should be lifted using minimum 6m chains or slings. These are supplied by the main contractor.
            <br />
            <br />
            When UNIPODS provides installation, we typically include all plant and machinery required for maneuvering the pods across the slab. However, we expect the main contractor to provide use of a site tower crane or mobile crane as agreed upon in the installation programme.
          </p>
        </div>
      </div>

      <div className="flex flex-row-reverse flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Snagging Process & Handover" src={ImageDeliveryInstallation5} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Snagging Process & Handover</p>

          <p className="font-book">
            Pods are delivered to the site with the door locked and secured. UNIPODS retain the key to the pod door and require the pods not be opened without the UNIPODS team in attendance. An agreement for joint inspections is required. A representative of UNIPODS along with representative from main contractor will jointly open and inspect each pod. A simple pre-prepared check sheet is used to carry out a series of simple checks for completeness and quality for every pod. Any deficiencies, missing items, or damage will be noted as well as the party responsible for ratification. The keys to each pod are handed over to the main contractor following this joint inspection. At this point, the condition of the pod is accepted and the responsibility for any further damage or missing items passes to the main contractor.
          </p>
        </div>
      </div>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Service & Commissioning Process" src={imagePodsCeilings} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Service & Commissioning Process</p>

          <p className="font-book">
            UNIPODS site representatives will attend the site for the final commissioning of our pods. This is done during the commissioning of the main project services and once all main connections (water & electrical supplies) are present and at full working pressure. It is imperative that all site installed supply pipework is fully flushed prior to connection being made to the pod manifold.
            <br />
            <br />
            Once this stage has been achieved, the UNIPODS representative will attend the site and will turn pods on by opening the isolation valve at the manifold inlet connection. A function test will be carried out to ensure systems are operating correctly.
          </p>
        </div>
      </div>
    </div>
  )
}
