import React from 'react'

import ImageMechanicalServices1 from 'images/unipod/mechanical_services_1.jpg'
import ImageMechanicalServices2 from 'images/unipod/mechanical_services_2.jpg'
import ImageMechanicalServices3 from 'images/unipod/mechanical_services_3.jpg'
import ImageMechanicalServices4 from 'images/unipod/mechanical_services_4.jpg'

export default function MechanicalEletricalServices() {
  return (
    <div>
      <p className="mb-12 sm:text-xl">
        <b>Mechanical & Electrical Services</b>
        <br />
        Generally, provision is made within the pod for all MEP services. All services are incorporated and terminate at an agreed location to simplify on-site connection to main systems. This includes, but is not limited to, water supply, pipework, drainage, soil and  waste pipework, electrical wiring systems, ventilation, and extract. In each case, the system design is detailed within our shop drawings which are issued and signed-off by the project design team and respective consultants prior to commencement of the pod manufacture.
      </p>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Water Supply Pipework" src={ImageMechanicalServices1} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Water Supply Pipework</p>

          <p className="font-book">
            Bathroom and Kitchen PODS will be supplied with all terminal fittings including taps, mixers, shower valves and shower heads and will include all required hot & cold supply pipework. The supply is generally fed from a single pair of hot & cold manifolds. The manifolds are located at an agreed accessible location so that MEP contractor has only one hot and one cold connection to make at each pod. All required service and isolation valves can be included within the pod design dependent upon specific design constraints. UNIPODS supply a main isolation valve at inlet connection to each manifold as standard.
            <br />
            <br />
            UNIPODS use a flexible Pex pipework system that confirms to all WRAS, BS, & EN standards for potable water. The system is installed using a pipe-in-pipe system. This means the pipe runs cast into concrete components or within wall cavities can be easily replaced should this ever be required.
            <br />
            <br />
            Pipework installations on every pod are rigorously tested as part of the factory production quality assurance procedures. Test results for each pod are recorded and supplied with the pod as record and confirmation of testing procedure (see “Testing & Quality Control” for more details”).
            <br />
            <br />
            It is assumed that the incoming supply system will be a nominally balanced, pressurized system set to meet the operating requirements of the specified fittings used within the pod. No allowance is made within the pod design for the provision of pressure reducing valves or flow regulators unless specifically requested.
            <br />
            <br />
            The main contractor must ensure that the site installed plumbing installations are fully flushed out prior to making connection to the water service manifolds as any debris in the system could damage the terminal fittings within the bathroom or cause them to malfunction. Where such symptoms arise, the responsibility for failure will lie with the main Contractor.
          </p>
        </div>
      </div>

      <div className="flex flex-row-reverse flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Drainage Systems" src={ImageMechanicalServices2} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Drainage Systems</p>

          <p className="font-book">
            Soil and waste drainage pipework will be included with all appliances within the pod. Connections will be made to appliances within the pod and will include water seal/trap (where required). The drainage pipework will run to an agreed termination point for on-site connection to main system (by others). Drainage runs within the pod can be accommodated in a number of ways depending on the location of appliance where drainage is needed, required pipe dimensions, and design limitations to ensure conformity with regulations.
            <br />
            <br />
            The following methods, or a combination of both, can be considered in each case:
            <br />
            <br />
            Horizontal drainage routes will run above the pod floor level. It will penetrate the pod wall and terminate at agreed location; possibly a service riser or shaft. The pipe termination will project approx 75mm beyond external face of pod wall to allow on-site connection. Pipes are generally concealed either by running within bulkheads or pipe boxing. For smaller diameters it is possible for the pipe to run within the depth of the pod wall cavity.
            <br />
            <br />
            Vertically drainage routes will terminate directly through the pod floor. This is done by casting a socket within the pod floor. Corresponding cut-out is required in the supporting slab below so that site connection can be made by pushing the pipe up into the socket provided.
            <br />
            <br />
            Unless stated otherwise all soil & waste drainage pipework within the pod will be solvent weld PVC & uPVC material. Fire sleeves are not fitted to waste pipework except where the provision of these has been clearly stated within our specifications and indicated on our detailed design drawings.
            <br />
            <br />
            All drainage systems fitted within the pod are factory tested as part of our factory production quality assurance procedures. Test results for each pod are recorded and supplied with the pod as a record and confirmation of testing procedure (see “Testing & Quality Control” for more details”).
            <br />
            <br />
            The services hook-up to each bathroom and kitchen requires it be undertaken in a manner that recognizes the fact that termination points of pod drainage are fixed and cannot be adjusted when connecting to main system pipework. We therefore recommend use of slip couplings when connecting to horizontal drainage terminations. Failure to adopt this technique will disturb or even dislocate factory tested pipework. Should this be the case then this would invalidate any warranty and may result in damage to finishes and fittings.
          </p>
        </div>
      </div>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Electrical Installations" src={ImageMechanicalServices3} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Electrical Installations</p>

          <p className="font-book">
            Each pod be supplied as fully finished room with all specified electrical components and wiring systems complete and installed in compliance with the required regulatory standards. All required wiring will be in place and terminations will be made at agreed upon locations (normally junction box on pod roof or duct wall) for simple on-site connection to the main system.
            <br />
            <br />
            The pods can be supplied with an external back-box for a light switch at the entrance door, however the switch-plate is supplied and installed by others on-site once the required finishing has been applied to the external face of the pod wall. Additional conduits and junction boxes required to serve site-installed electrical systems can be incorporated within the pod design and manufacture upon request.
          </p>
        </div>
      </div>

      <div className="flex flex-row-reverse flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Ventilation Systems" src={ImageMechanicalServices4} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Ventilation Systems</p>

          <p className="font-book">
            Ventilation and extract grilles can be accommodated within the pod design and installed within the pod wall or ceiling. The final connection from grille to the main system would be carried out by the main contractor.
          </p>
        </div>
      </div>
    </div>
  )
}
