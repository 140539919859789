import React from 'react'

import imageWallTiling from 'images/unipod/wall_tiling.jpg'

export default function WallTiling() {
  return (
    <div>
      <p className="font-bold mb-10 text-2xl">Wall Tiling</p>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Wall Tiling" src={imageWallTiling} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p>
            The pods are tiled by a fully skilled and trained workforce. Wall tiling is also completed in a temperature controlled area to optimize adhesive performance. All tiling layouts are carried out in accordance to the client’s specifications. Any tiles requiring cutouts are cut off-line and brought to workstations to increase tilers productivity and precision.
          </p>
        </div>
      </div>
    </div>
  )
}
