import React from 'react'

import ImagePodsAssembly2 from 'images/unipod/pos_assembly_2.jpg'
import ImagePodsAssembly3 from 'images/unipod/pos_assembly_3.jpg'
import ImagePodsAssembly4 from 'images/unipod/pos_assembly_4.jpg'

export default function PodsAssembly() {
  return (
    <div>
      <p className="mb-12 sm:text-xl">
        <b>PODS Assembly</b>
        <br />
        The floor, wall, and ceiling panels are brought to the assembly point. The floor is leveled and wall panels are populated with aligned inserts and fixing points. The ceiling panel is then lowered into place and secured. Lifting rods then connect to the pod floor and terminate at ceiling level for connection of lifting eyes for site lift/installation (see pod lifting). All fixings are secured and checked as part of the quality assurance procedure for pod assembly.
      </p>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Tanking System" src={ImagePodsAssembly2} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Tanking System</p>

          <p className="font-book">
            Bathroom and Kitchen PODS receive a comprehensive tanking system to ensure water tightness. The overall design of tanking system, tile adhesives, and grouting systems have been developed in conjunction with BASF Construction Chemicals (PCI) specifications. All of this is done with consideration given to our assembly process, transportation, and installation of each Bathroom and Kitchen POD.
            <br />
            <br />
            The process begins with application of primer to all floor and wall surfaces. The tanking system is then applied to the whole floor area and floor/wall junctions returning 200mm up all walls. The same tanking system is also applied to the full height of the walls in wet areas such as shower areas. The tanking system consists of a solvent-free synthetic resin which is painted on to the floor and walls and dries to form a flexible waterproof membrane. This membrane is applied in two coats and combined with waterproof reinforced membrane tape at floor to wall junctions and vertical corners (wall to wall junctions).
          </p>
        </div>
      </div>

      <div className="flex flex-row-reverse flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Tiling, Adhesives, and Grout" src={ImagePodsAssembly3} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Tiling, Adhesives, and Grout</p>

          <p className="font-book">
            Tile, adhesives, and grout are developed in conjunction with BASF Construction Chemicals (PCI) specifications. The adhesives and grouts are selected for their specific application based on tiling substrate, selected floor, and wall finishes.
            <br />
            <br />
            PODS Floors are tiled and grouted prior to full pod assembly. The floors are placed on trestles at waist level providing best possible conditions and ergonomics for our tile fixers, thereby improving the efficiency of the process. Wall tiling and grouting is carried out on the production line following cabin assembly. All tiling within the pods is set-out to designed tile grids enabling each pod of a specific type to have identical set-out points and tile grids.
            <br />
            <br />
            Our designers work with the client’s consultants to achieve an agreed upon tile design for each pod type. We leverage our experience to achieve the best possible aesthetic whilst minimizing waste and avoiding unsightly cuts or detailing.
          </p>
        </div>
      </div>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Internal Finishes" src={ImagePodsAssembly4} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Internal Finishes</p>

          <p className="font-book">
            UNIPODS works with almost all specified finishes. We’re experienced in ceramic, porcelain, natural stone, and glass products. We will source and procure specified materials or work with our clients nominated supply chain to do so. This also applies to sanitary ware, brassware, and all other fittings. In most cases, we can access an equal or alternative product through our supply chain as a value engineering option.
          </p>
        </div>
      </div>
    </div>
  )
}
