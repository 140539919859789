import React from 'react'

import ImagePreparationForDispatch1 from 'images/unipod/preparation_for_dispatch_1.jpg'

export default function PreparationForDispatch() {
  return (
    <div>
      <p className="mb-12 sm:text-xl">
        <b>Preparation For Dispatch</b>
      </p>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Cleaning & Protection" src={ImagePreparationForDispatch1} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Cleaning & Protection</p>

          <p className="font-book">
            Once all necessary testing procedures have been completed to standard, the pod receives a thorough builder’s clean prior to being wrapped and ready for dispatch. All construction debris, traces of plaster, and grout are removed. Protection is applied internally as necessary to avoid any damage during transportation. The temporary or final door is installed and secured and protection is applied as necessary. The pod is then fully wrapped in heavy duty polythene protection and is moved to our “storage-ready” area for dispatch to site.
          </p>
        </div>
      </div>
    </div>
  )
}
