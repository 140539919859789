import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import icon from 'images/carousel-left.png'

export default function ContactMap() {
  const customIcon = new L.Icon({
    iconUrl: icon,
    iconSize: [25, 25]
  })

  const positionUae = [25.3928176, 55.9828152]

  const bounds = [
    positionUae,
  ]

  return (
    <div style={{ height: 340 }}>
      <MapContainer
        bounds={bounds}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <Marker
          icon={customIcon}
          position={positionUae}
        >
          <Popup>
            <p>
              <b>UAE Head Office</b>
              <br />
              <br />
              Al Ghail Industrial Area
              <br />
              P.O. Box 34132
              <br />
              Ras Al Khaimah, UAE
            </p>
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  )
}
