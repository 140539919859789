import React from 'react'

import imageCabinAssembly from 'images/unipod/cabin_assembly.jpg'

export default function CabinAssembly() {
  return (
    <div>
      <p className="font-bold mb-10 text-2xl">Cabin Assembly</p>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Cabin Assembly" src={imageCabinAssembly} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p>
            The wall elements are mechanically bolted to the pre-tiled floors and from wall to wall creating a rigid box. The pre-manufactured ceilings are optionally made from cold rolled steel profiles with moisture resistance fiberboard or box frame with moisture resistance fiberboard.
          </p>
        </div>
      </div>
    </div>
  )
}
