import React from 'react'

import imageSecondPhase from 'images/unipod/second_phase_plumbing.jpg'

export default function Plumbing2() {
  return (
    <div>
      <p className="font-bold mb-10 text-2xl">2nd Phase of Plumbing</p>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="2nd Phase of Plumbing" src={imageSecondPhase} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p>
            Pre-assembled plumbing elements are line fed to the pods and fitted to the pre-plumbed locations for a clean and easy process. Each pod is then fitted with mirrors, lights, shower screens, etc. as per the approved shop drawings and agreed specifications.
          </p>
        </div>
      </div>
    </div>
  )
}
