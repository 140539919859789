import React from 'react'

import ImageEightStep1 from 'images/unipod/eight_step1.jpg'
import ImageEightStep2 from 'images/unipod/eight_step2.png'
import ImageEightStep3 from 'images/unipod/eight_step3.jpg'
import ImageEightStep4 from 'images/unipod/eight_step4.jpg'
import ImageEightStep5 from 'images/unipod/eight_step5.jpg'
import ImageEightStep6 from 'images/unipod/eight_step6.jpg'
import ImageEightStep7 from 'images/unipod/eight_step7.jpg'
import ImageEightStep8 from 'images/unipod/eight_step8.jpg'

export default function EightSteps() {
  return (
    <div>
      <h2 className="page-title mb-6">8 Step Process</h2>

      <p className="mb-12 sm:text-xl">
        <b>8 Step Modular Off-site construction</b>
        <br />
        UNIPODS’ refined 8 step process guarantees unmatched customer service, construction, and delivery.
      </p>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Superior Advising Team" src={ImageEightStep1} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Superior Advising Team</p>

          <p className="font-book">
            At UNIPODS, our goal is to build a quality product at a price that suits your budget. We will advise you in selecting quality suppliers with competitive pricing.
          </p>
        </div>
      </div>

      <div className="flex flex-row-reverse flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Design Approval" src={ImageEightStep2} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Design Approval</p>

          <p className="font-book">
            Each POD is designed according to the agreed-upon architect’s drawings and specifications. Production does not begin without the client’s approval.
          </p>
        </div>
      </div>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Reference POD Meeting" src={ImageEightStep3} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Reference POD Meeting</p>

          <p className="font-book">
            Every client is invited to a Reference POD Meeting for a comprehensive presentation of a finished POD. This meeting is the client’s opportunity to critique the POD before the main production begins.
          </p>
        </div>
      </div>

      <div className="flex flex-row-reverse flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Production Begins" src={ImageEightStep4} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Production Begins</p>

          <p className="font-book">
            Our production line workstations at Ras Al Khaimah factory are customized to enhance our construction quality and efficiency. All tools, anchors, and adhesives are sourced from top quality suppliers.
          </p>
        </div>
      </div>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Quality Checks" src={ImageEightStep5} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Quality Checks</p>

          <p className="font-book">
            Our team performs rigorous daily quality checks throughout the production process. We track quality by way of a unique barcode assigned to each POD.
          </p>
        </div>
      </div>

      <div className="flex flex-row-reverse flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="POD Delivery" src={ImageEightStep6} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">POD Delivery</p>

          <p className="font-book">
            UNIPODS are delivered fully fitted and install-ready using large trailers. Each trailer can carry 4 to 6 PODS varying in weight and size.
          </p>
        </div>
      </div>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Installation Process" src={ImageEightStep7} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Installation Process</p>

          <p className="font-book">
            All pre-assembled plumbing elements are line fed to the PODS and fitted to pre-plumbed locations for a clean and easy process. The PODS are then fitted with mirrors, lights, shower screens, etc. in accordance with the agreed level of specifications.
          </p>
        </div>
      </div>

      <div className="flex flex-row-reverse flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Contractor Commissioning" src={ImageEightStep8} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">Contractor Commissioning</p>

          <p className="font-book">
            The M&E Contractor connects the plug and play POD to the main water and electrical systems on site. The contractor then undertakes the final testing and commissioning of the overall system. However, UNIPODS will assist in this process and check for any final snagging within the pod itself.
          </p>
        </div>
      </div>
    </div>
  )
}
