import React, { useEffect, useState } from 'react'
import AboutPods from './AboutPods'
import Benefits from './Benefits'
import TechnicalData from './technical_data'
import ProductionFlow from './production_flow'
import EightSteps from './EightSteps'
import Installation from './Installation'
import BtnTab from './BtnTab'

export default function ModularConstruction() {
  const isMobile = window.screen.width < 640
  const defaultTab = 'AboutPods'
  const [activeTab, setActiveTab] = useState(isMobile ? '': defaultTab)
  const [activeTechnicalTab, setActiveTechnicalTab] = useState(isMobile ? '' : 'PodConstruction')
  const [activeProductionTab, setActiveProductionTab] = useState(isMobile ? '' : 'Casting')

  useEffect(() => {
    if (!window.location.search.split('page=')[1]) return

    setActiveTab(window.location.search.split('page=')[1])
  }, []);

  const primaryTabs = [
    {name: 'AboutPods',      text: 'About pods'},
    {name: 'Benefits',       text: 'Benefits'},
    {name: 'TechnicalData',  text: 'Technical data'},
    {name: 'ProductionFlow', text: 'Production flow'},
    {name: '8Steps',         text: '8 steps process'},
    {name: 'Installation',   text: 'Installation'},
  ]

  const technicalDataTabs = [
    {name: 'PodConstruction',             text: 'Pod Construction'},
    {name: 'PodsAssembly',                text: 'PODS Assembly'},
    {name: 'MechanicalEletricalServices', text: 'Mechanical & Electrical Services'},
    {name: 'PreparationForDispatch',      text: 'Preparation For Dispatch'},
    {name: 'DeliveryInstallation',        text: 'Delivery & Installation'},
    {name: 'Warranties',                  text: 'Warranties, Maintenance, and Defects'},
    {name: 'AdditionalInformation',       text: 'Additional Information'},
    {name: 'CapacityOutput',              text: 'Capacity / Output'},
    {name: 'PeopleExperience',            text: 'People & Experience'},
  ]

  const productionTabs = [
    {name: 'Casting',       text: 'Casting'},
    {name: 'FloorTiling',   text: 'Floor Tiling'},
    {name: 'CabinAssembly', text: 'Cabin Assembly'},
    {name: 'Tanking',       text: 'Tanking The Pod'},
    {name: 'Plumbing1',     text: 'First Phase of Plumbing'},
    {name: 'WallTiling',    text: 'Wall Tiling'},
    {name: 'Plumbing2',     text: '2nd Phase of Plumbing'},
    {name: 'LiveTesting',   text: 'Live Testing'},
    {name: 'Completion',    text: 'Completion'},
    {name: 'Delivery',      text: 'Delivery'},
  ]

  return (
    <main className="bg-gradient-modular page-padding">
      <section className="container container--main">
        <div className="sm:flex -mx-8">
          <aside className="hidden px-8 py-6 sm:block sm:py-12 text-center sm:w-3/12">
            {primaryTabs.map((tab) => (
              <div className="mb-6 sm:mb-8" key={tab.name}>
                <BtnTab
                  activeTab={activeTab}
                  name={tab.name}
                  onClick={(e) => {
                    e.preventDefault()
                    setActiveTab(activeTab === tab.name ? defaultTab : tab.name)
                  }}
                  primary
                  text={tab.text}
                />

                {activeTab === 'TechnicalData' && tab.name === 'TechnicalData' && (
                  <div className="pt-6 pl-8">
                    {technicalDataTabs.map((tab) => (
                      <div className="mb-4" key={tab.name}>
                        <BtnTab
                          activeTab={activeTechnicalTab}
                          name={tab.name}
                          onClick={(e) => {
                            e.preventDefault()
                            setActiveTechnicalTab(tab.name)
                          }}
                          text={tab.text}
                        />
                      </div>
                    ))}
                  </div>
                )}

                {activeTab === 'ProductionFlow' && tab.name === 'ProductionFlow' && (
                  <div className="pt-6 pl-8">
                    {productionTabs.map((tab) => (
                      <div className="mb-4" key={tab.name}>
                        <BtnTab
                          activeTab={activeProductionTab}
                          name={tab.name}
                          onClick={(e) => {
                            e.preventDefault()
                            setActiveProductionTab(tab.name)
                          }}
                          text={tab.text}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </aside>

          <div className="bg-white px-8 py-6 sm:py-12 sm:w-9/12">
            <div className="mb-6 sm:hidden text-center">
              <BtnTab
                activeTab={activeTab}
                name={primaryTabs[0].name}
                onClick={(e) => {
                  e.preventDefault()
                  setActiveTab(activeTab === primaryTabs[0].name ? '' : primaryTabs[0].name)
                }}
                primary
                text={primaryTabs[0].text}
              />
            </div>

            {activeTab === 'AboutPods' && <AboutPods />}

            <div className="mb-6 sm:hidden text-center">
              <BtnTab
                activeTab={activeTab}
                name={primaryTabs[1].name}
                onClick={(e) => {
                  e.preventDefault()
                  setActiveTab(activeTab === primaryTabs[1].name ? '' : primaryTabs[1].name)
                }}
                primary
                text={primaryTabs[1].text}
              />
            </div>

            {activeTab === 'Benefits' && <Benefits />}

            <div className="mb-6 sm:hidden text-center">
              <BtnTab
                activeTab={activeTab}
                name={primaryTabs[2].name}
                onClick={(e) => {
                  e.preventDefault()
                  setActiveTab(activeTab === primaryTabs[2].name ? '' : primaryTabs[2].name)
                }}
                primary
                text={primaryTabs[2].text}
              />
            </div>

            {activeTab === 'TechnicalData'
              && <TechnicalData
                activeTechnicalTab={activeTechnicalTab}
                setActiveTechnicalTab={setActiveTechnicalTab}
                technicalDataTabs={technicalDataTabs}
              />
            }

            <div className="mb-6 sm:hidden text-center">
              <BtnTab
                activeTab={activeTab}
                name={primaryTabs[3].name}
                onClick={(e) => {
                  e.preventDefault()
                  setActiveTab(activeTab === primaryTabs[3].name ? '' : primaryTabs[3].name)
                }}
                primary
                text={primaryTabs[3].text}
              />
            </div>

            {activeTab === 'ProductionFlow'
              && <ProductionFlow
                activeProductionTab={activeProductionTab}
                productionTabs={productionTabs}
                setActiveProductionTab={setActiveProductionTab}
              />
            }

            <div className="mb-6 sm:hidden text-center">
              <BtnTab
                activeTab={activeTab}
                name={primaryTabs[4].name}
                onClick={(e) => {
                  e.preventDefault()
                  setActiveTab(activeTab === primaryTabs[4].name ? '' : primaryTabs[4].name)
                }}
                primary
                text={primaryTabs[4].text}
              />
            </div>

            {activeTab === '8Steps' && <EightSteps />}

            <div className="mb-6 sm:hidden text-center">
              <BtnTab
                activeTab={activeTab}
                name={primaryTabs[5].name}
                onClick={(e) => {
                  e.preventDefault()
                  setActiveTab(activeTab === primaryTabs[5].name ? '' : primaryTabs[5].name)
                }}
                primary
                text={primaryTabs[5].text}
              />
            </div>

            {activeTab === 'Installation' && <Installation />}
          </div>
        </div>
      </section>
    </main>
  )
}
