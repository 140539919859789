import React from 'react'

export default function BtnTab({
  activeTab,
  name,
  onClick,
  primary,
  text,
}) {
  const defaultBtnClass = primary ? 'btn--primary-outlined' : 'btn--primary btn--primary-white'

  return (
    <a
      className={`
        btn btn--small py-2 w-full
        ${activeTab === name ? 'btn--primary' : defaultBtnClass}
      `}
      href="#"
      onClick={onClick}
      style={{ lineHeight: '24px' }}
    >
      {text}
    </a>
  )
}
