import React from 'react'

import imageFloorTiling from 'images/unipod/floor_tiling.jpg'

export default function FloorTiling() {
  return (
    <div>
      <p className="font-bold mb-10 text-2xl">Floor Tiling</p>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Floor Tiling" src={imageFloorTiling} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p>
            The floor tiling is carried out on tiling frames at the optimum height in the main production hall. This ensures a constant supply of quality tiled and grouted floors. They are protected prior to being moved to the next process of pod assembly. The area is temperature controlled to create optimal working conditions and UNIPODS uses only quality adhesives, approved for the UAE climate.
          </p>
        </div>
      </div>
    </div>
  )
}
