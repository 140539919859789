import React from 'react'

import ImageAboutPods1 from 'images/unipod/about-pods-1.png'
import ImageAboutPods2 from 'images/unipod/about_pods_2.jpg'

export default function AboutPods() {
  return (
    <div>
      <h2 className="page-title mb-6">About pods</h2>

      <p className="mb-12 sm:text-xl">
        Unipods is the perfect turn-key solution partner for the modern contractor. The modules are manufactured off-site and tailor-made to the client’s specifications. Our solution is a pre-engineered, pre-fitted, and ready-plumbed unit. It’s the perfect ‘Plug & Play’ concept for the fast-paced construction industry.
      </p>

      <div className="items-center mb-16 sm:flex">
        <div className="sm:w-7/12">
          <img alt="About pods" src={ImageAboutPods1} />
        </div>

        <div className="sm:w-5/12">
          <div className="box-white box-white--about-pods">
            <p>
            Each unit is manufactured in a factory-controlled environment to consistently achieve the highest standards of quality. Once the module passes our testing and commissioning, we deliver and install it per a pre-agreed sequence, completing your construction in record time.
            </p>
          </div>
        </div>
      </div>

      <div className="flex-row-reverse items-center mb-16 sm:flex">
        <div className="sm:w-7/12">
          <img alt="About pods" src={ImageAboutPods2} />
        </div>

        <div className="sm:w-5/12">
          <div className="box-white box-white--about-pods">
            <p>
            Our years of experience, emphasis on quality, and innovative leaps have brought us to the forefront of our competition. We guarantee our customers units that they’ll love at prices they prefer with convenience and peace of mind to match.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
