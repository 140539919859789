import React, { useEffect, useState } from 'react'
import qs from 'qs'
import axiosService from '../../../common/AxiosService'

export default function Projects({ params: { pod_type, load_more }, projects: projectsProp, projects_size }) {
  const [projects, setProjects] = useState(projectsProp)
  const [projectsCount, setProjectsCount] = useState(projects_size)
  const [podType, setPodType] = useState(pod_type)
  const [loadMore, setLoadMore] = useState(load_more)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if(loaded) {
      const query = qs.stringify({ pod_type: podType, load_more: loadMore })
      window.history.pushState({}, '', `${window.location.origin}${window.location.pathname}?${query}`)
      getProjects()
    } else {
      setLoaded(true)
    }
  }, [podType, loadMore])

  const changePodType = ((e, podType) => {
    e.preventDefault()
    setPodType(podType)
    setLoadMore(0)
  })

  const getProjects = () => {
    axiosService.get(`/projects.json`, { params: { pod_type: podType, load_more: loadMore }})
    .then(({ projects: p, projects_size }) => {
      setProjects(p)
      setProjectsCount(projects_size)
    })
  }

  return (
    <main className="page-padding">
      <section className="bg-tuna-o-8">
        <div className="container container--main py-12">
          <h2 className="page-title mb-5">Customer Satisfaction Guaranteed</h2>
          <p className="font-book lg:text-2xl mb-11 text-xl">Our years of experience, emphasis on quality, and innovative leaps have brought us to the forefront of our competition. We guarantee our customers units that they’ll love at prices they prefer with the convenience and peace of mind to match.</p>
          <div className="items-center lg:flex justify-between">
            <div className="font-bold lg:mt-0 mt-5 sm:text-left text-center text-xl">
              <a href="#" onClick={e => changePodType(e, undefined)} className={`mr-4 ${podType ? 'text-tuna-o-50' : 'text-punch' }`}>All</a>
              <a href="#" onClick={e => changePodType(e, 'Bathroom')} className={`mr-4 ${podType === 'Bathroom' ? 'text-punch' : 'text-tuna-o-50' }`}>Bathroom pods</a>
              <a href="#" onClick={e => changePodType(e, 'Kitchen')} className={`mr-4 ${podType === 'Kitchen' ? 'text-punch' : 'text-tuna-o-50' }`}>Kitchen pods</a>
              <a href="#" onClick={e => changePodType(e, 'MultiUse')} className={`mr-4 ${podType === 'MultiUse' ? 'text-punch' : 'text-tuna-o-50' }`}>Multi-use pods</a>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container container--main py-12">
          <div className="flex flex-wrap mb-12 -mx-4">
            { projects.map(project => (
              <div key={project.id} className="mb-8 px-4 lg:w-1/2 w-full">
                <div className="box-white p-6">
                  <div className="relative">
                    <img src={project.hero_image.url} alt={project.hero_image_alt} className="block mb-8 rounded-xl h-60 md:h-96 w-full object-cover" />
                    <p className="absolute font-book left-0 px-4 pt-4 sm:text-xl text-white top-0">
                      <b className="sm:text-2xl">{project.name}</b>
                      <br/>
                      {project.location}
                    </p>
                    { +project.count > 0 &&
                      <p className="absolute bg-tuna bottom-0 font-bold left-0 mb-4 ml-4 p-2 rounded text-white">{project.count} Pods</p>
                    }
                    { project.ongoing &&
                      <p className="absolute bg-white bottom-0 font-bold mb-4 mr-4 p-2 right-0 rounded text-tuna">Ongoing</p>
                    }
                  </div>
                  { project.project_type === 'case_study' ?
                    <a href={`/projects/${project.id}-${project.slug}`} className="btn btn--primary btn--primary-black text-center w-full">Case study</a>
                    : <a href={`/projects/${project.id}-${project.slug}`} className="btn btn--primary text-center w-full">Read more</a>
                  }
                </div>
              </div>
            ))
            }
          </div>
          <div className="flex justify-center">
            { projectsCount >= +loadMore + 6 &&
              <button className="btn btn--primary-outlined" onClick={() => setLoadMore(loadMore + 6)}>Load more</button>
            }
          </div>
        </div>
      </section>
    </main>
  )
}