import React from 'react'

import imageLiveTesting from 'images/unipod/live_testing.jpg'

export default function LiveTesting() {
  return (
    <div>
      <p className="font-bold mb-10 text-2xl">Live Testing</p>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Live Testing" src={imageLiveTesting} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p>
            Live electricity and water tests are implemented by fully qualified personnel and a test certificate is assigned to each pod and sent to main contractors.
          </p>
        </div>
      </div>
    </div>
  )
}
