import React from 'react'

import imageTankingThePod from 'images/unipod/tanking_the_pod.jpg'

export default function Tanking() {
  return (
    <div>
      <p className="font-bold mb-10 text-2xl">Tanking The Pod</p>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="Tanking The Pod" src={imageTankingThePod} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p>
            The pod is tanked with rubber membrane in all wet areas. The membrane is used to seal all the joints to create a watertight box. Overflow pipes can be cast-in all floors to minimize damage in case of a leak or water overflow.
          </p>
        </div>
      </div>
    </div>
  )
}
