import React from 'react'

import ImagePodConstuction1 from 'images/unipod/pod_construciton_1.jpg'
import ImagePodConstuction2 from 'images/unipod/pod_construciton_2.jpg'
import ImagePodConstuction3 from 'images/unipod/pod_construciton_3.jpg'
import ImagePodConstuction4 from 'images/unipod/pod_construciton_4.jpg'

export default function PodConstruction() {
  return (
    <div>
      <p className="mb-12 sm:text-xl">
        <b>Pod Construction</b>
        <br />
        UNIPODS main components, the floor slab, wall panels, and ceiling panel, are fabricated separately then combined and polished to perfection.
      </p>

      <img alt="Pod Construction" className="block mb-12" src={ImagePodConstuction1} />

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="POD Floors" src={ImagePodConstuction2} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">POD Floors</p>

          <p className="font-book">
            The POD floor is a self-supporting, pre-casted concrete slab. The floor is cast with a perimeter up-stand to provide maximum water-tightness. At a minimum, floor thickness is 50mm (concrete) plus finishes. Maximum concrete thickness may increase depending upon layout and overall size of the pod.
            <br />
            <br />
            All necessary holes and penetrations for service and drainage runs can be incorporated within the floor design as well as inserts necessary for pod assembly and lifting. POD floors are cast with built-in gradient/falls and steps as necessary to form wet room shower floors or shower tray details.
            <br />
            <br />
            <b>Reinforced Concrete Strength: 30MN/m²</b>
            <br />
            <b>Reinforced Concrete Density: 2300 – 2400 Kg/m³</b>
          </p>
        </div>
      </div>

      <div className="flex flex-row-reverse flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="POD Walls" src={ImagePodConstuction3} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">POD Walls</p>

          <p className="font-book">
            POD walls offer a Lightweight Steel Frame Gypsum Board Lined Walls construction material option.
            <br />
            <br />
            Lightweight Steel Walls are framed by cold-rolled galvanised steel and c-section studs. They are lined internally with moisture resistant gypsum plasterboard to form a rigid wall construction ready for factory applied internal finishes.
            <br />
            <br />
            Our factory-made c-section studs eliminate significant material off-cut wastes associated with traditional on-site gypsum board standard length studs.  They are produced via an automated roll forming process whereby each section is cut to the exact length with notches, swage, rivet punch, and countersinks. The studs are formed from 0.70 gauge galvanized steel. A thicker and more rigid material than typical gypsum board metal stud sections. The wall panels are designed with studs at max 400mm centers providing a robust wall panel construction.
            <br />
            <br />
            These walls are lined internally with moisture resistant gypsum plasterboard ready to accept finishes within the factory.
            <br />
            <br />
            Gypsum Plaster board is selected to meet specific performance requirements for the finished wall. Insulation can be included between studs if required. It is normal for UNIPODS to provide external boarding to specific pod walls that have been designed to form riser shaft or duct areas and are required to meet specific fire and acoustic performance ratings.
            <br />
            <br />
            Generally, the external layer of dry-lining is applied on-site by the main contractor once the pod has been placed in its final location within the building. This facilitates alignment of external faces to the surrounding partitioning walls executed on site with specific tolerances.
          </p>
        </div>
      </div>

      <div className="flex flex-wrap mb-12 -mx-4">
        <div className="mb-6 px-4 sm:mb-0 sm:w-1/2">
          <img alt="POD Ceilings" src={ImagePodConstuction4} />
        </div>

        <div className="px-4 sm:w-1/2">
          <p className="font-bold mb-4 text-guardsman-red">POD Ceilings</p>

          <p className="font-book">
            POD ceilings are a single panel consisting of the steel sub-frame and lined internally with gypsum plasterboard. Prior to delivery, all joints are taped and filled, and the ceiling receives a seamless anti-fungal painted finish. A protective layer plywood or cement board is provided externally which allows movement flexibility on top of the pod.
            <br />
            <br />
            Based on your needs, we offer two sub-frame material options:
            <br />
            A mild steel welded sub-frame constructed from hot rolled hollow section steel and coated with anti-rust protection.
            <br />
            A cold rolled galvanised steel c-section stud frame.
            <br />
            <br />
            POD ceilings typically incorporate access panels or demountable ceiling tiles to allow access to services located in the ceiling void above the pod. Access panels are fully coordinated with MEP services and architectural guidelines and are generally positioned as per project requirements.
          </p>
        </div>
      </div>
    </div>
  )
}
